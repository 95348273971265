<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>基础配置</a-breadcrumb-item>
            <a-breadcrumb-item>用户管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div
          v-if="!visible"
          class="flow-d-row"
          style="float: right; align-items: center"
        >
          <a-button style="margin: 0 15px" type="primary" @click="showDrawer">
            新增用户
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1">禁用 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['gender'].value"
                  v-else-if="i == '性别'"
                  placeholder="请选择性别"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 未知 </a-select-option>
                  <a-select-option value="1"> 男 </a-select-option>
                  <a-select-option value="2"> 女 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content style="background: #fff; padding: 24px; margin: 0">
        <!-- <div>
          当前为<span class="packages">{{
            packagelist.packages.length > 1
              ? packagelist.packages[0].packageNameSnapshot +
                "等" +
                packagelist.packages.length +
                "个套餐"
              : packagelist.packages[0].packageNameSnapshot
          }}</span
          >-用户总量: <span class="packages">{{ packagelist.total }}</span
          >-已添加: <span class="packages">{{ packagelist.usage }}</span> -剩余:
          <span class="packages">{{
            packagelist.total - packagelist.usage
          }}</span>
        </div> -->
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="Userlist"
            :rowClassName="rowClassName"
          >
            <span slot="status" slot-scope="text, record">
              <span v-if="text == -1">未激活</span>
              <a-switch
                v-else
                :checked="text == 0 ? true : false"
                default-checked
                @change="onchangestate(record)"
              />
            </span>
            <template slot="action" slot-scope="text, record">
              <a-popover title="初始密码">
                <template slot="content">
                  <p>{{ record.initPassword }}</p>
                </template>
                <a v-show="record.state == -1" @click="oninitPassword(record)"
                  >查看密码</a
                >
              </a-popover>
              &nbsp;&nbsp;
              <a @click="onEdit(record)">编辑</a>&nbsp;&nbsp;
              <a-popconfirm
                title="你确认要删除该用户么?"
                ok-text="是"
                cancel-text="否"
                @confirm="onDetele(record)"
                @cancel="cancel"
              >
                <a>删除</a>
              </a-popconfirm>
            </template>
            <a slot="gender" slot-scope="item">{{
              item == '0' ? '未知' : item == '1' ? '男' : '女'
            }}</a>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
      <a-modal
        :title="title"
        :visible="visible"
        @ok="onSubmit"
        @cancel="onClose"
      >
        <a-form-model
          ref="ruleForm"
          :rules="rules"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="username" prop="username" label="用户名">
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.username"
            />
          </a-form-model-item>
          <a-form-model-item ref="name" prop="name" label="真实姓名">
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.name"
            />
          </a-form-model-item>
          <a-form-model-item prop="gender" label="性别">
            <a-select v-model="form.gender">
              <a-select-option value="0"> 未知 </a-select-option>
              <a-select-option value="1"> 男 </a-select-option>
              <a-select-option value="2"> 女 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="code" prop="code" label="编号">
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.code"
            />
          </a-form-model-item>
          <a-form-model-item ref="email" prop="email" label="邮件">
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.email"
            />
          </a-form-model-item>
          <a-form-model-item ref="mobile" prop="mobile" label="手机号">
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.mobile"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-layout>
    <!-- <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="
          background: #fff;
          padding: 24px;
          margin: 0;
        "
      >
        
      </a-layout-content>
    </a-layout> -->
  </a-layout>
</template>
<script>
import moment from 'moment'
const columns = [
  {
    title: '用户名',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
  },
  {
    title: '性别',
    dataIndex: 'gender',
    scopedSlots: { customRender: 'gender' },
  },
  {
    title: '编号',
    dataIndex: 'code',
  },
  {
    title: '邮件',
    dataIndex: 'email',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
// import moment from "moment";
import http from '../../../http'
export default {
  data() {
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        // 合法的邮箱
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      columns,
      visible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      title: '',
      textlist: ['用户名', '性别', '状态', '编号', '邮件'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      params: {
        current: 1,
        pageSize: 10,
        username: {
          value: '',
          op: '%like%',
        },
        code: {
          value: '',
          op: '%like%',
        },
        email: {
          value: '',
          op: '%like%',
        },
        state: {
          value: undefined,
          op: '=',
        },
        gender: {
          value: undefined,
          op: '=',
        },
      },
      total: 1,
      selectedRowKey: [],
      packagelist: {
        total: 0,
        usage: 0,
      },
      Id: '',
      Userlist: [],
      form: {
        id: '',
        username: '',
        name: '',
        gender: '',
        state: '',
        code: '',
        mobile: '',
        email: '',
      },
      e: '',
      rules: {
        username: [
          {
            required: true,
            message: '用户名为大小写字母、数字,且六位以上',
            trigger: 'blur',
            min: 6,
            max: 16,
          },
        ],
        name: [
          {
            required: true,
            message: '真实姓名长度为2-16个字符',
            trigger: 'blur',
            min: 2,
            max: 16,
          },
        ],
        gender: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: '编号长度为1-16个字符',
            trigger: 'blur',
            min: 1,
            max: 16,
          },
        ],
        email: [
          {
            required: true,
            trigger: 'blur',
            validator: checkEmail,
          },
        ],
        mobile: [
          {
            required: true,
            message: '手机号格式错误或为空',
            trigger: 'blur',
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    onchangetime(e) {
      if (e == null) {
        this.params['patrol_date'].value = undefined
      } else {
        this.params['patrol_date'].value = moment(e).format('YYYY-MM-DD')
      }
    },
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['username'].value = values.用户名
        this.params['code'].value = values.编号
        this.params['email'].value = values.邮件
      })
      this.getUser()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['state'].value = undefined
      this.params['gender'].value = undefined
    },
    toggle() {
      this.expand = !this.expand
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.id == '') {
            this.AddUser(this.form)
          } else {
            this.putUser(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    onClose() {
      this.visible = false
      this.form = {
        id: '',
        username: '',
        name: '',
        gender: '',
        state: '',
        code: '',
        mobile: '',
        email: '',
      }
    },
    showDrawer() {
      if (this.packagelist.total == this.packagelist.usage) {
        this.$message.error('您当前套餐,用户数量不足!')
        return
      }
      this.visible = true
      this.title = '新增'
    },
    async getconfiguserrest() {
      try {
        const res = await http.getconfiguserrest()
        const { success, data } = res.data
        if (success) {
          this.packagelist = data
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.stateUser(e.id, this.form.state)
    },
    async stateUser(id, state) {
      try {
        const res = await http.stateUser(id, state)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.title = '编辑'
      this.visible = true
      this.getUsernId(item.id)
    },
    onpagesize(e) {
      this.params.current = e
      this.getUser()
    },
    // 删除
    onDetele(e) {
      this.deleteuser([e.id])
    },
    async deleteuser(id) {
      try {
        const res = await http.deleteuser(id)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.getconfiguserrest()
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    //查
    async getUser() {
      try {
        const res = await http.getUser(this.params)
        const { success, data } = res.data
        if (success) {
          this.Userlist = data.users.records
          this.total = data.users.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('ex:', ex)
      }
    },
    async getUsernId(id) {
      try {
        const res = await http.getUsernId(id)
        const { success, data } = res.data
        if (success) {
          this.form.code = data.user.code
          this.form.email = data.user.email
          this.form.gender = JSON.stringify(data.user.gender)
          this.form.mobile = data.user.mobile
          this.form.name = data.user.name
          this.form.username = data.user.username
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 新增
    async AddUser(form) {
      try {
        const res = await http.AddUser(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.getconfiguserrest()
          setTimeout(() => {
            this.onClose()
          }, 100)
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    async putUser(form) {
      try {
        const res = await http.putUser(form)
        const { success, msg } = res.data
        if (success) {
          setTimeout(() => {
            this.onClose()
            this.timer()
          }, 100)
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.success('参数错误')
      }
    },

    onpagesizet(e) {
      this.sitparams.current = e
    },
    timer() {
      return setTimeout(() => {
        this.params.current = 1
        this.getUser()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getUser()
    this.getconfiguserrest()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    // this.callback();
    // this.getsts();
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKey = selectedRowKeys
          //   console.log(this.selectedRowKey);
          console.log(selectedRows)
        },
      }
    },
  },
}
</script>
<style scoped>
span {
  display: inline-block;
}
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
.ant-form-item {
  height: 50px;
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.check-box-ro {
  background: #f3f7ff;
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 10px;
  margin-bottom: 6px;
  display: inline-block;
  font-size: 14px;
  align-items: center;
}
::v-deep .ant-popover-inner-content {
  padding: 5;
}
::v-deep .ant-form-item-label {
  text-align: right;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
.title-top {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  border-left: 3px solid #1890ff;
  color: #000;
  padding-left: 10px;
}
.packages {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
</style>
